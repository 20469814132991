.voice-assistant {
    text-align: center;
}

.mic-visualizer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 200px;
    margin: 0 auto;
    position: relative;
}

.siri-wave {
    position: absolute;
    width: 100px;
    height: 100px;
    border: 4px solid #FF4081;
    border-radius: 50%;
    background-color: transparent;
}

.siri-wave:nth-child(2) {
    border-color: #FF80AB;
}

.siri-wave:nth-child(3) {
    border-color: #FF4081;
}
